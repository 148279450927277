import CreatorLayout from '../pages/creators/layout'
import AdminLayout from '../pages/admin/layout'
import MainLayout from '../pages/layout'

export const getLayout = ({ Component, page, router }) => {
  const nestedLayout = (page) => {
    if (router.pathname.includes("creators")) {
      return (
        <CreatorLayout>{page}</CreatorLayout>
      )
    }

    if (router.pathname.includes("admin")) {
      return (
        <AdminLayout>{page}</AdminLayout>
      )
    }

    return <MainLayout>{page}</MainLayout>;
  }

  return nestedLayout(page)
};
