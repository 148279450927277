import React, { useState } from 'react'
import copy from 'copy-to-clipboard'
import { motion } from 'framer-motion'
import cn from 'classnames'

const variants = {
  show: { opacity: 1 },
  hide: { opacity: 0 }
}

interface CopyTextProps {
  children: JSX.Element[] | JSX.Element
  copyText: string
  confirmText?: string
  className?: string
}

const CopyToClipboard = ({ children, copyText, confirmText = 'Copied!', className }: CopyTextProps) => {
  const [ copied, setCopied ] = useState(false)
  const handleCopy = () => {
    copy(copyText)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }

  return (
    <div className={className}>
      {React.Children.map(children, (child) => React.cloneElement(child, { onClick: handleCopy }))}
      <div className="flex flex-col items-center justify-center relative">
        <motion.div
          initial="hide"
          animate={copied ? 'show' : 'hide'}
          variants={variants}
          className={cn('copy-component', { copied: copied })}>
          <span className="confirm-text">{confirmText}</span>
        </motion.div>
      </div>
    </div>
  )
}

export default CopyToClipboard
