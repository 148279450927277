import { useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { useRouter } from 'next/router'
import { subDataState } from '@/util/states'
import MainLayout from '../layout';

export default function AdminLayout({ children }) {
// AUTH RESTRICTION HERE FOR ADMINS ONLY
  const router = useRouter();
  const subData = useRecoilValue(subDataState)
  const loading = subData === "pending"

  useEffect(() => {
    if (subData.admin || loading) return;

    router.push('/');
}, [subData]);

  if (!subData || !subData.admin) return null;

  return (
    <MainLayout>
      <div>{children}</div>
    </MainLayout>
  )
}
