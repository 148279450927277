import { initializeApp, getApps } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyClDw-mySyM7TfJ5uQAOaPw8MfPwFt4Edc',
  authDomain: 'beyond-the-pines.firebaseapp.com',
  projectId: 'beyond-the-pines',
  storageBucket: 'beyond-the-pines.appspot.com',
  messagingSenderId: '280937205242',
  appId: '1:280937205242:web:c3368bd7af01c0097b6843',
  measurementId: 'G-FSVL095RNR'
}

let firebaseApp

if (!getApps().length) {
  firebaseApp = initializeApp(firebaseConfig)
}

export const app = firebaseApp
export const auth = getAuth(app)
export const db = getFirestore()

// export async function checkIfSignedIn () {

//   const promise = new Promise((resolve, reject) => {
//     onAuthStateChanged(auth, (user) => {
//       if (user) resolve(user)
//       else reject('No user is logged in.')
//     })
//   })

//   return await promise
// }
