export const phi = 1.618034
export const sqrtPhi = 1.618034

export const mobileBreakpoint = 800
export const contentHeight = 'calc(100vh - 157px)'
export const carouselAnimDuration = 0.3
export const formSubmitTimeout = 800

export const DRAFT = 'draft'
export const LIVE = 'live'
export const REJECTED = 'rejected'

export const ADMIN = 'admin'
export const CREATORS = 'creators'
export const ALL = 'all'

export const DISCOVERIES = 'Discoveries'
export const PURCHASES = 'Purchases'

export const ACTIVE_TAB_CLASS = 'inline-block py-3 px-4 text-white bg-olive rounded-lg active'
export const INACTIVE_TAB_CLASS = 'inline-block px-4 py-3 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-black'

// Post/Upload Types
export const PAID = 'paid'
export const FREE = 'free'
