import Link from 'next/link'
import FadeImg from '@/components/fadeImg'
import CopyToClipboard from './CopyToClipboard'

export default function Footer({ isMobile, withFooter = true }: { isMobile: boolean, withFooter: boolean }) {
  if(!withFooter) return null
  return (
    <footer className="p-4 pt-14 pb-6 flex items-end" style={{ flex: '1 1 auto' }}>
      <div className='w-full'>
        <hr />
          {isMobile ?
            <div className="text-xs text-light flex flex-col pt-4">
              <Link href="/terms">Terms of Use</Link>
              <div className="mt-2" >
                <Link href="/privacy-policy">Privacy Policy</Link>
              </div>
              <div className="mt-2" >
                <Link href="/return-policy">Refund/Cancelation Policy</Link>
              </div>
              <div className="mt-2" >
                <CopyToClipboard copyText="support@beyondthepines.com" confirmText="email address copied"><span className="cursor-pointer">support@beyondthepines.com</span></CopyToClipboard>
              </div>
              <Socials />
              <div className="mt-6">© 2022 BeyondThePines.com</div>
            </div>
          :
            <div className="flex items-center w-full justify-between">
            <div className="text-xs text-light flex flex-col pt-4">
              <Link href="/terms">Terms of Use</Link>
              <div className="mt-2" >
                <Link href="/privacy-policy">Privacy Policy</Link>
              </div>
              <div className="mt-2" >
                <Link href="/return-policy">Refund/Cancelation Policy</Link>
              </div>
              <div className="mt-2" >
              <CopyToClipboard copyText="support@beyondthepines.com" confirmText="email address copied"><span className="cursor-pointer">support@beyondthepines.com</span></CopyToClipboard>
              </div>
              <div className="mt-6">© 2022 BeyondThePines.com</div>
              <div className="text-right">
              </div>
            </div>
            <Socials />

            </div>
            }
      </div>
    </footer>
  )
}

const Socials = () => {
  const SocialsImg = ({ src, href, alt }: { src: string; href: string; alt: string }) => (
    <a href={href} target="_blank" rel="noreferrer">
      <FadeImg
        alt={alt}
        className="inline dt:mx-7 mr-4 mt-6 opacity-50
          dt:w-8 dt:h-8 mb:w-6 mb:h-6
          cursor-pointer
        "
        src={src}
      />
    </a>
  )

  return (
    <div className="flex h-full opacity-50">
      <SocialsImg
        src="/socialsLogos/ig.svg"
        href="https://www.instagram.com/beyondthepinesmagazine/?hl=en"
        alt="Link to Our Instagram"
      />
      <SocialsImg
        src="/socialsLogos/tiktok.svg"
        href="https://www.tiktok.com/@badboi?lang=en"
        alt="Link to Our Tiktok"
      />
      <SocialsImg
        src="/socialsLogos/opensea.svg"
        href="https://opensea.io/BADBOI-"
        alt="Link to Our OpenSea"
      />
    </div>
  )
}
