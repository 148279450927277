import { onAuthStateChanged, User } from '@firebase/auth'

import { auth } from '@/util/firebase'

export default async function getCurrentUser(): Promise<User | null> {
  const promise = new Promise<User | null>(async (resolve) => {
    onAuthStateChanged(auth, (user) => {
      if (!user || !auth.currentUser) {
        resolve(null)
        return
      }

      resolve(auth.currentUser)
    })
  })

  return await promise
}
