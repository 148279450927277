import { useState, Dispatch, SetStateAction } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useRecoilValue } from 'recoil'

import MenuToggle from '@/components/Header/MenuToggle'
import Menu from '@/components/Header/Menu'
import { subDataState, userInfoState } from '@/util/states'
import { Children } from '@/util/types'
import Image from 'next/image'

import logo from '../../assets/btp-logo.png'

// MOBILE

const MobileHeader = () => {
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false)

  return (
    <header className="flex flex-col justify-center h-header">
      <TopHeaderMobile menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} />
      <Menu menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} />
    </header>
  )
}

const TopHeaderMobile = ({
  menuIsOpen,
  setMenuIsOpen
}: {
  menuIsOpen: boolean
  setMenuIsOpen: Dispatch<SetStateAction<boolean>>
}) => {
  return (
    <div className="h-header flex flex-col justify-center bg-white">
      <MenuToggle menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} />
      <TopHeadingMobile />
    </div>
)}

const TopHeadingMobile = () =>
  <Link href="/">
    <h2
      className="
        font-logo
        tracking-upperTitle font-mulish-med text-center
        h-height-1-mob leading-height-1-mob
        text-headerTabs
        cursor-pointer
      "
    >
      <Image src={logo} width={120} height={50} className="logo" />
    </h2>
  </Link>

// DESKTOP

interface Link {
  text: string
  href?: string
  external?: boolean
  onClick?: () => void
}

const LinkWrapper = ({
  href,
  isExternal,
  children
}: {
  href: string | undefined,
  isExternal: boolean | undefined
  children: Children
}) =>
  <>
    {
      href
      ? (
        isExternal
        ? <a href={href} target="_blank" rel="noreferrer">
            {children}
          </a>
        : <Link href={href} passHref>
            {children}
          </Link>
      )
      : children

    }
  </>

const NavLink = ({ link, active }: { link: Link, active: boolean }) =>
  <LinkWrapper href={link.href} isExternal={link.external}>
    <div
      key={link.text}
      className={`
        nav-link
        cursor-pointer
        font-mulish-med
        uppercase text-11px tracking-wider ${active ? 'text-black font-bold' : 'text-gray'}
        hover:text-black
        p-2
        peer
      `}
      {...(!!link.onClick ? { onClick: link.onClick } : null)}
    >
      {link.text}
    </div>
  </LinkWrapper>


const DesktopNavLinks = ({ links, side }: { links: Link[], side: 'left' | 'right' }) => {
  const router = useRouter()
  return(
    <div id={`desktop-nav-links-${side}`} className="flex z-50">
      {links.map((link, index) =>
        <NavLink
          active={!!link.href && router.pathname.includes(link.href)}
          key={`nav-link${index}`}
          link={link}
        />
      )}
    </div>
  )
}

const DesktopHeader = () => {
  const subData = useRecoilValue(subDataState)
  const userInfo = useRecoilValue(userInfoState)

  if (subData === 'pending') return null

  const rightLinks = []

  if (subData?.creator){
    rightLinks.push({
      text: 'dashboard',
      href: '/creators'
    })
  }

  if (!subData.isSubbed)
    rightLinks.push({
      text: 'subscribe',
      href: '/subscribe'
    })
  
  if (userInfo)
    rightLinks.push({
      text: 'my account',
      href: '/my-account'
    })
  else
    rightLinks.push({
      text: 'login',
      href: '/login'
    })

  const leftLinks = [
    {
      text: "blogs",
      href: "/blogs",
    },
    {
      text: "book",
      href: "/issues"
    },
    {
      text: "factory",
      href: "/discover"
    },
    {
      text: "muse",
      href: "/muses"
    },
    {
      text: "charity",
      href: "/charity"
    },
    {
      text: "about",
      href: "/about"
    },
    {
      text: "shop",
      href: "https://beyondthepines.myshopify.com/",
      external: true
    },
  ]

  return (
    <header className="header relative flex items-center justify-between h-header bg-white px-5 border-b border-black border-opacity-10">
      <DesktopNavLinks links={leftLinks} side="left" />
      <Link href="/">
        <span className="
          font-logo
          text-medium
          uppercase font-bold cursor-pointer px-2 absolute justify-self-center w-screen left-0
          tracking-upperTitle font-mulish-med text-center leading-height-2
          dt-800:text-12px dt-900:text-13px dt-1k:text-headerTabs dt:pt-px
        ">
          <Image src={logo} width={140} height={59} className="logo" />
        </span>
      </Link>
      <DesktopNavLinks links={rightLinks} side="right" />
    </header>
  )
}

export default function Header({ isMobile }: { isMobile: boolean }) {
  return (
    <div className="h-header flex items-center" style={{ flex: '0 1 auto' }}>
      <div className="fixed z-40 w-full top-0 h-header">
        { isMobile ? <MobileHeader /> : <DesktopHeader /> }
      </div>
    </div>
  )
}
