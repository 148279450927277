import { atom, RecoilState } from 'recoil'
import { IUserInfo, ISubData } from '@/util/types'

export const userInfoState: RecoilState<IUserInfo | undefined | 'pending'> = atom({
  key: 'currentUser',
  default: 'pending' as IUserInfo | undefined | 'pending'
})

export const subDataState: RecoilState<ISubData | 'pending'> = atom({
  key: 'subData',
  default: 'pending' as ISubData | 'pending'
})