import { useRef, useEffect } from 'react'
import useOnScreen from '@/util/useOnScreen'

export default function FadeImg ({
  className,
  style,
  src,
  alt,
  disableRightClick = false
}: {
  className?: string
  style?: Record<string, any>
  src: string
  alt: string
  disableRightClick?: boolean
}) {

  const ref = useRef<HTMLImageElement>(null)
  const isVisible = useOnScreen(ref)

  useEffect(() => {

    if (!disableRightClick) return
    if (!ref.current) return

    const preventRightClick = (event: MouseEvent) => event.preventDefault()
    ref.current.addEventListener('contextmenu', preventRightClick)
    return () => {
      if (!ref.current) return
      ref.current.removeEventListener('contextmenu', preventRightClick)
    }

  }, [ref.current])

  return (
    <img
      style={{
        ...style,
        opacity: isVisible ? '1' : '0'
      }}
      ref={ref} 
      src={src}
      className={`transition-opacity duration-500 ${className}`}
      alt={alt}
      draggable={false}
    />
  )
}