import { useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { useRouter } from 'next/router'
import { subDataState } from '@/util/states'
import MainLayout from '../layout';

export default function CreatorLayout({ children }) {
// AUTH RESTRICTION HERE FOR CREATORS AND ADMINS ONLY
  const router = useRouter();
  const subData = useRecoilValue(subDataState)
  const loading = subData === "pending"

  useEffect(() => {
    if (loading) return;
    if (!subData.creator && !subData.admin) {
      router.push('/');
    }
  }, [subData, loading, router]);

  if (loading || (!subData.creator && !subData.admin)) return null;

  return (
    <MainLayout>
      {children}
    </MainLayout>
  )
}
