import { useState, useEffect, RefObject } from 'react'

export default function useOnScreen(ref: RefObject<HTMLImageElement>) {

  const [isIntersecting, setIntersecting] = useState(false)

  const observer = new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting)
  )

  useEffect(() => {
    if (!ref.current) return
    observer.observe(ref.current)
    return () => { observer.disconnect() }
  }, [ref.current])

  return isIntersecting
}