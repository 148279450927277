import { useState, useEffect } from 'react'
import { mobileBreakpoint } from '@/util/constants'

export default function useIsMobile() {
  const [isMobile, setIsMobile] = useState<boolean>()

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= mobileBreakpoint ? true : false)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return isMobile
}
