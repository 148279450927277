import { useEffect, useState } from 'react'
import type { AppProps } from 'next/app'
import '../styles/globals.css'
import { RecoilRoot, useRecoilState, useSetRecoilState } from 'recoil'
import { useRouter } from 'next/router'
import Head from 'next/head'
import Script from 'next/script'

import { getLayout } from '@/util/getLayout'
import getCurrentUser from '@/util/getCurrentUser'
import { subDataState, userInfoState } from '@/util/states'
import ConfirmBirthday from '@/components/ConfirmBirthday'
import useIsMobile from '@/util/useIsMobile'

import 'swiper/css/bundle'
import 'swiper/css/lazy'
import 'video-react/dist/video-react.css'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import 'react-image-lightbox/style.css'

import 'src/styles/swiperHomepage.css'
import 'src/styles/lightbox.css'
import 'src/styles/swiperIssueMb.css'
import 'src/styles/swiperBlogDt.css'
import 'src/styles/swiperBlogMb.css'
import 'src/styles/nav-menu.css'
import 'src/styles/index.css'
import 'src/styles/about.css'
import 'src/styles/blog.css'
import 'src/styles/modal.css'
import 'src/styles/pages.css'
import 'src/styles/interview.css'
import 'src/styles/copy-to-clipboard.css'
import 'src/styles/subscribe.css'
import 'src/styles/relatedPosts.css'
import 'src/styles/section.css'

import getSubData from '@/util/getSubData'
import getCheckoutData from '@/util/getCheckoutData'

function MyApp({ Component, pageProps }: AppProps) {
  const [customerId, setCustomerId] = useState<string>()
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (url: any) => {
      // @ts-ignore
      if(typeof window.gtag.pageview !== 'undefined') window.gtag.pageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  useEffect(() => {
    if (router.pathname === '/subscribe/success') return

    // @ts-ignore
    if (window._refersion) {
      //@ts-ignore
      window._refersion()
    } else console.error('window._refersion does NOT exist')
  }, [])

  useEffect(() => {
    if (typeof router.query.session_id === 'string' && router.pathname === '/subscribe/success') {
      getCheckoutData(router.query.session_id).then((checkoutData) => {
        setCustomerId(checkoutData.session.customer)
      })
    }
  }, [router.isReady])

  useEffect(() => {
    if (customerId) {
      // @ts-ignore
      window._refersion(function () {
        // @ts-ignore
        _rfsn._setSource('STRIPE')
        // @ts-ignore
        _rfsn._addCart(customerId)
      })
    }
  }, [customerId])

  // METADATA

  const title = pageProps?.title || 'Beyond the Pines'
  const description = pageProps?.description || 'Beyond the Pines, by Badboi'
  const image = pageProps?.image || 'https://www.beyondthepines.com/images/social.jpg'

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta charSet="UTF-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.beyondthepines.com" />
        <meta property="og:image" content={image} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@beyondthepines" />
        <meta name="twitter:creator" content="@beyondthepines" />
      </Head>
      <Script
        id="imported_refersion_script"
        strategy="beforeInteractive"
        src={`//refersion.com/tracker/v3/${process.env.REFERSION_API_KEY}.js`}
      />

      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=G-JPQDLN29PS`}
      />
      <Script
        // @ts-ignore
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-JPQDLN29PS', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />

      {/* Hotjar Tracking Code for https://www.beyondthepines.com/ */}
      <Script
        dangerouslySetInnerHTML={{
          __html: `
          (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:3593632,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `,
        }}
      />
      <RecoilRoot>
        <GlobalRecoilHooks Component={Component} pageProps={pageProps} />
      </RecoilRoot>
    </>
  )
}

const GlobalRecoilHooks = ({ Component, pageProps }: any) => {
  const [userInfo, setUserInfo] = useRecoilState(userInfoState)
  const [, setSubData] = useRecoilState(subDataState)

  const router = useRouter()
  const isMobile = useIsMobile()

  useEffect(() => {
    if (!router.isReady) return

    const sessionId =
      typeof router.query.session_id === 'string' && !router.query.change_payment
        ? router.query.session_id
        : undefined

    if(userInfo === 'pending') {
      getCurrentUser().then((currentUser) => {
        const userInfo = currentUser
          ? {
              uid: currentUser.uid,
              email: currentUser.email || null,
              displayName: currentUser.displayName || null,
              emailVerified: currentUser.emailVerified
            }
          : undefined
        setUserInfo(userInfo)
        getSubData(userInfo, sessionId).then((subData) => {
          setSubData(subData)
        })
      })
  
    }
    
  }, [router.isReady, router.pathname])

  if (userInfo === 'pending') return null
  if (isMobile === undefined) return null

  return getLayout({
    Component: Component,
    page: <Component {...pageProps} />,
    router
  })
}

export default MyApp
