import { db } from '@/util/firebase'
import { doc, getDoc, getDocs, collection, onSnapshot } from 'firebase/firestore'
import getCheckoutData from '@/util/getCheckoutData'
import { ISubData, IUserInfo } from '@/util/types'

export default async function getSubData(
  userInfo: IUserInfo | undefined,
  sessionId?: string
): Promise<ISubData> {
  const getSubDataPromise = new Promise<ISubData>(async (resolve) => {
    if (!userInfo?.uid) {
      resolve({ isSubbed: false })
      return
    }

    let discordId
    let allAccess
    let unlockedDiscoveries
    let username
    let admin = false
    let creator = false

    const docRef = doc(db, 'users', userInfo.uid)
    const docSnap = await getDoc(docRef)
    const subColRef = collection(db, "users", userInfo.uid, "posts");
    const qSnap = await getDocs(subColRef)
    const unlocked = qSnap.docs.map(d => (d.id))

    if (docSnap.exists()) {
      const data = docSnap.data()
      sessionId = data.sessionId
      discordId = data.discordId
      allAccess = data.allAccess
      unlockedDiscoveries = data.unlockedDiscoveries || []
      admin = data.admin
      creator = data.creator
      username = data.username
      if (!sessionId) {
        resolve({ isSubbed: false, unlocked, admin, creator, unlockedDiscoveries, username })
        return
      }
    } else {
      console.error('error: user document does not exist!')
      resolve({ isSubbed: false, unlocked, admin, creator, unlockedDiscoveries, username })
      return
    }

    const checkoutData = await getCheckoutData(sessionId)

    const isSubscribed = checkoutData?.session?.subscription?.status === 'active' ? true : false
    if (!isSubscribed) {
      resolve({ isSubbed: false, unlocked, admin, creator, unlockedDiscoveries, username })
      return
    }

    const planNickname: string = checkoutData.session.subscription.plan.nickname
    const tier = planNickname.includes('Standard') ? 'Standard' : 'Beyond'
    const monthlyOrYearly = planNickname.includes('Monthly') ? 'Monthly' : 'Yearly'
    const subscriptionId = checkoutData.session.subscription.id
    const willCancel = checkoutData.session.subscription.cancel_at_period_end
    const customerId = checkoutData.session.customer

    resolve({
      isSubbed: true,
      tier: tier,
      monthlyOrYearly: monthlyOrYearly,
      subscriptionId: subscriptionId,
      willCancel: willCancel,
      customerId: customerId,
      discordId,
      allAccess,
      unlocked,
      unlockedDiscoveries,
      admin,
      username,
      creator,
    })
  })

  return await getSubDataPromise
}
