import { Dispatch, SetStateAction } from 'react'
import { motion } from 'framer-motion'

export default function MenuToggle({
  menuIsOpen,
  setMenuIsOpen
}: {
  menuIsOpen: boolean
  setMenuIsOpen: Dispatch<SetStateAction<boolean>>
}) {
  const handleClick = () => setMenuIsOpen((prev: boolean) => !prev)

  return (
    <motion.nav
      className="h-height-1-mob w-height-1-mob absolute"
      initial={false}
      animate={menuIsOpen ? 'open' : 'closed'}
    >
      <button className="h-full m-auto ml-5 block" onClick={handleClick}>
        <svg width="23" height="23" viewBox="0 0 23 23">
          <Path
            variants={{
              closed: { d: 'M 2 2.5 L 20 2.5' },
              open: { d: 'M 3 16.5 L 17 2.5' }
            }}
          />
          <Path
            d="M 2 9.423 L 20 9.423"
            variants={{
              closed: { opacity: 1 },
              open: { opacity: 0 }
            }}
            transition={{ duration: 0.1 }}
          />
          <Path
            variants={{
              closed: { d: 'M 2 16.346 L 20 16.346' },
              open: { d: 'M 3 2.5 L 17 16.346' }
            }}
          />
        </svg>
      </button>
    </motion.nav>
  )
}

function Path(props: any) {
  return <motion.path fill="transparent" strokeWidth="0.5" stroke="hsl(0, 0%, 18%)" {...props} />
}
