import { Dispatch, SetStateAction } from 'react'
import Link from 'next/link'
import { motion } from 'framer-motion'
import { FiExternalLink } from 'react-icons/fi'
import { useRecoilValue } from 'recoil'
import { Children } from '@/util/types'
import { subDataState, userInfoState } from '@/util/states'

const variants = {
  open: {
    x: 0
  },
  closed: {
    x: '-100%'
  }
}

const transition = {
  type: 'tween',
  ease: 'easeInOut',
  duration: 0.25
}

export default function Menu({
  menuIsOpen,
  setMenuIsOpen
}: {
  menuIsOpen: boolean,
  setMenuIsOpen: Dispatch<SetStateAction<boolean>>
}) {

  return (
    <motion.div
      className="absolute w-full overflow-hidden z-50 nav-menu bg-white"
      // height of header
      style={{ top: 60 }}
      animate={menuIsOpen ? 'open' : 'closed'}
      initial={false}
      variants={variants}
      transition={transition}
    >
      <MenuItems setMenuIsOpen={setMenuIsOpen} />
    </motion.div>
  )
}

const MenuItems = ({ setMenuIsOpen }: { setMenuIsOpen: Dispatch<SetStateAction<boolean>> }) => {
  const userInfo = useRecoilValue(userInfoState)
  const subData = useRecoilValue(subDataState)

  if (subData === 'pending') return null

  return (
    <>
      {subData?.creator && <MenuOption href="/creators" setMenuIsOpen={setMenuIsOpen}>CREATORS DASHBOARD</MenuOption>}

      <MenuOption href="/" setMenuIsOpen={setMenuIsOpen}>HOME</MenuOption>
      
      <MenuOption href="/blogs" setMenuIsOpen={setMenuIsOpen}>BLOGS</MenuOption>

      <MenuOption href="/muses" setMenuIsOpen={setMenuIsOpen}>MUSE</MenuOption>

      <MenuOption href="/discover" setMenuIsOpen={setMenuIsOpen}>FACTORY</MenuOption>

      <MenuOption href="/issues" setMenuIsOpen={setMenuIsOpen}>BOOK</MenuOption>

      <MenuOption href="/charity" setMenuIsOpen={setMenuIsOpen}>CHARITY</MenuOption>
      
      <MenuOption href="https://beyondthepines.myshopify.com/" setMenuIsOpen={setMenuIsOpen} shouldOpenInNewTab>SHOP</MenuOption>

      <MenuOption href="/about" setMenuIsOpen={setMenuIsOpen}>ABOUT</MenuOption>

      {!!userInfo ? (
        <>
          {
            !subData.isSubbed &&
            <MenuOption href={'/subscribe'} setMenuIsOpen={setMenuIsOpen}>SUBSCRIBE</MenuOption>
          }
          <MenuOption href="/my-account" setMenuIsOpen={setMenuIsOpen}>MY ACCOUNT</MenuOption>
        </>
      ) : (
        <>
          <MenuOption href="/subscribe" setMenuIsOpen={setMenuIsOpen}>SUBSCRIBE</MenuOption>

          <MenuOption href="/login" setMenuIsOpen={setMenuIsOpen}>LOGIN</MenuOption>
        </>
      )}
    </>
  )
}

const MenuOption = ({
  children,
  href,
  shouldOpenInNewTab = false,
  setMenuIsOpen
}: {
  children: Children
  href: string
  shouldOpenInNewTab?: boolean
  setMenuIsOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const MenuButton = ({ onClick }: { onClick?: () => void }) => (
    <button onClick={onClick} className="flex items-center justify-between w-full h-12 bg-white border-b border-black border-opacity-10 cursor-pointer">
      <span className="font-soehne-kraftig text-sm float-left leading-height-1-mob ml-5 tracking-widest">
        {children}
      </span>
      { shouldOpenInNewTab && <FiExternalLink className="mr-6" /> }
    </button>
  )

  MenuButton.displayName = "Menu Button"

  return shouldOpenInNewTab
    ? 
      <a href={href} target="_blank" rel="noreferrer" onClick={() => setMenuIsOpen(false)}>
        <MenuButton />
      </a>
    :
      <Link href={href}>
        <div>
          <MenuButton onClick={() => setMenuIsOpen(false)} />
        </div>
      </Link>
}
